<template>
  <DetailTemplate :customClass="'doc-detail detail-page'">
    <template v-slot:header-title>
      <div
        class="d-block"
        style="height: 58.44px"
        v-if="!lodash.isEmpty(detail)"
      >
        <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
          {{ getTitle() }}
        </h1>
        <p class="m-0 text--secondary">
          <v-chip
            v-if="false"
            small
            label
            content="Status"
            v-tippy
            class="mr-2 text-uppercase"
            text-color="white"
            color="cyan"
          >
            {{ detail.status_text }}
          </v-chip>
          <v-icon v-if="false" x-small color="grey darken-2">mdi-record</v-icon>
          <v-chip
            small
            label
            content="Last Modified Time"
            v-tippy
            class="text-uppercase"
            color="cyan"
            text-color="white"
          >
            {{ detail.modified_at }}
          </v-chip>
          <template v-if="detail.expiry_date">
            <v-icon x-small class="mx-2" color="grey darken-2"
              >mdi-record</v-icon
            >
            <v-chip
              small
              label
              content="Document Expiry Date"
              v-tippy
              class="text-uppercase"
              text-color="white"
              color="cyan"
            >
              {{ formatDate(detail.expiry_date) }}
            </v-chip>
          </template>
        </p>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-if="detail.status == 1"
        :disabled="dataLoading"
        class="mr-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="goToEdit()"
      >
        <v-icon small left>mdi-pencil</v-icon>
        Edit
      </v-btn>
      <v-btn
        :disabled="dataLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        color="cyan white--text"
        v-on:click="downloadRawFile()"
      >
        <v-icon small left>mdi-download</v-icon>
        Raw File
      </v-btn>
      <v-btn
        :disabled="dataLoading"
        class="ml-2 custom-grey-border custom-bold-button"
        v-on:click="goBack()"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container class="custom-border-top">
        <v-row v-if="dataLoading">
          <v-col md="12" class="mt-10">
            <v-progress-linear
              color="cyan"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col md="7" class="document-recipient-detail-template">
            <table
              v-if="detail.doc_recipients && detail.doc_recipients.length"
              class="width-100 document-recipient-table"
            >
              <thead>
                <tr>
                  <th class="simple-table-th">#</th>
                  <th class="simple-table-th">Recipient</th>
                  <th class="simple-table-th">Status</th>
                  <th class="simple-table-th">Download</th>
                </tr>
              </thead>
              <tbody id="doc-recipient-rows">
                <tr
                  v-for="(row, index) in detail.doc_recipients"
                  :key="index"
                  :id="`doc-recipient-row-${row.id}`"
                  v-on:click="showActivity(row)"
                >
                  <td class="simple-table-td">{{ index + 1 }}.</td>
                  <td class="simple-table-td">
                    <p class="m-0">
                      {{ row.first_name }} {{ row.last_name }}
                      <em
                        class="text-lowercase text--secondary"
                        v-if="row.email"
                        >({{ row.email }})</em
                      >
                    </p>
                  </td>
                  <td class="simple-table-td">
                    <v-chip
                      small
                      label
                      content="Status"
                      v-tippy
                      class="text-uppercase"
                      :color="getStatusColor(row.status)"
                      :text-color="getStatusTextColor(row.status)"
                    >
                      {{ getStatusText(row.status) }}
                    </v-chip>
                  </td>
                  <td class="simple-table-td">
                    <v-btn
                      v-if="row.file"
                      v-on:click="downloadPDF(row)"
                      icon
                      color="cyan white--text"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <p v-else class="m-0">No Document</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col md="5" class="custom-border-left">
            <h3 class="custom-border-bottom pb-3 font-weight-600">ACTIVITY</h3>
            <table class="w-100" v-if="!lodash.isEmpty(recipient)">
              <tbody>
                <tr>
                  <th class="font-size-14" width="150">Recipient</th>
                  <td class="font-size-14">
                    <template v-if="recipient.name">
                      <span class="text-capitalize">{{ recipient.name }}</span>
                      <em
                        class="ml-2 text--secondary text-lowercase"
                        v-if="recipient.email"
                        >({{ recipient.email }})</em
                      >
                    </template>
                    <em v-else>No Recipient</em>
                  </td>
                </tr>
                <tr>
                  <th class="font-size-14" width="150">Viewed</th>
                  <td class="font-size-14">
                    <template v-if="!lodash.isEmpty(recipient.last_history)"
                      >{{ recipient.recipient_histories.length }} time</template
                    >
                    <em v-else>Not Viewed Yet</em>
                  </td>
                </tr>
                <tr>
                  <th class="font-size-14" width="150">Total time spent</th>
                  <td class="font-size-14">
                    <template v-if="!lodash.isEmpty(recipient.last_history)">{{
                      recipient.total_minutes
                    }}</template>
                    <em v-else>No Time Spent Yet</em>
                  </td>
                </tr>
                <tr>
                  <th class="font-size-14" width="150">Last viewed</th>
                  <td class="font-size-14">
                    <template v-if="!lodash.isEmpty(recipient.last_history)"
                      ><span
                        :content="
                          formatDateTime(recipient.last_history.finished_at)
                        "
                        class="cursor-pointer"
                        v-tippy
                        >{{ recipient.last_history.finished_at_human }}</span
                      ></template
                    >
                    <em v-else>Not Viewed Yet</em>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="width-100 mt-5"
              v-if="
                false &&
                !lodash.isEmpty(recipient) &&
                recipient.recipient_histories.length
              "
            >
              <thead>
                <tr>
                  <th class="simple-table-th">#</th>
                  <th class="simple-table-th">Started At</th>
                  <th class="simple-table-th">Finished At</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in recipient.recipient_histories"
                  :key="index"
                >
                  <td class="cursor-default simple-table-td">
                    {{ index + 1 }}
                  </td>
                  <td class="cursor-default simple-table-td">
                    {{ formatDateTime(row.started_at) }}
                  </td>
                  <td class="cursor-default simple-table-td">
                    {{ formatDateTime(row.finished_at) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <h3 class="mt-6 custom-border-bottom pb-3 font-weight-600">
              TIMELINE
            </h3>
            <div class="doc-detail-status-timeline">
              <v-timeline
                v-if="
                  !lodash.isEmpty(recipient) &&
                  recipient.status_histories.length
                "
                class="py-0"
                dense
                clipped
              >
                <v-timeline-item
                  v-for="(status_history, index) in recipient.status_histories"
                  :key="index"
                  class="py-0"
                  :color="getStatusHistoryColor(status_history.action)"
                  small
                >
                  <v-row>
                    <v-col cols="12">
                      <v-chip
                        small
                        label
                        content="Status"
                        v-tippy
                        class="text-uppercase"
                        :color="getStatusHistoryColor(status_history.action)"
                        :text-color="
                          getStatusHistoryTextColor(status_history.action)
                        "
                      >
                        <template v-if="status_history.action == 'submit'"
                          >Submitted</template
                        >
                        <template v-else>{{ status_history.action }}</template>
                      </v-chip>
                      <br />
                      <span
                        class="font-size-14 m-0"
                        v-html="status_history.description"
                      ></span>
                      <span
                        class="font-size-14 m-0"
                        v-if="
                          status_history.action == 'viewed' &&
                          !lodash.isEmpty(status_history.recipient_history)
                        "
                      >
                        for
                        <label class="my-0 py-0 font-weight-600">{{
                          status_history.recipient_history.duration_human
                        }}</label></span
                      >
                      <br />
                      <em
                        class="font-size-12 text--secondary cursor-pointer"
                        :content="formatDateTime(status_history.added_at)"
                        v-tippy
                        >{{ status_history.created_at }}</em
                      >
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </DetailTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { GET } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  mixins: [CommonMixin],
  data() {
    return {
      detail: {},
      recipient: {},
      dataLoading: true,
      docId: null,
    };
  },
  methods: {
    downloadRawFile() {
      const { raw_file_path } = this.detail;
      if (raw_file_path) {
        window.open(raw_file_path, "_blank");
      }
    },
    getTitle() {
      const title = [];
      if (this.detail.barcode) {
        title.push(this.detail.barcode);
      }
      if (this.detail.name) {
        const name = this.detail.name.split(".");
        name.splice(name.length - 1, 1);
        title.push(name.join(""));
      }
      return title.join(" • ");
    },
    showActivity(row) {
      const lights = document.getElementsByClassName("row-active");
      while (lights.length) {
        lights[0].classList.remove("row-active");
      }

      const element = document.getElementById(`doc-recipient-row-${row.id}`);
      if (element) {
        element.classList.add("row-active");
      }

      this.recipient = new Object({
        name: row.first_name,
        email: row.email,
        last_history: row.last_history,
        recipient_histories: row.recipient_histories,
        status_histories: row.status_histories,
        total_minutes: row.total_minutes,
      });
    },
    goToEdit() {
      this.$router.push(
        this.getDefaultRoute("create.doc", {
          params: { id: this.detail.id },
        })
      );
    },
    downloadPDF(row) {
      if (row.file) {
        window.open(row.file, "_blank");
      }
    },
    getStatusText(status) {
      /*1 => Pending, 2 => Sent, 3 => View, 4 => Filled*/
      if (status == 1) {
        return "Pending";
      } else if (status == 2) {
        return "Sent";
      } else if (status == 3) {
        return "Viewed";
      } else if (status == 4) {
        return "Filled";
      } else if (status == 5) {
        return "Submitted";
      }
    },
    getStatusColor(status) {
      /*1 => Pending, 2 => Sent, 3 => View, 4 => Filled*/
      if (status == 2) {
        return "cyan";
      } else if (status == 3) {
        return "light-blue accent-3";
      } else if (status == 4) {
        return "orange";
      } else if (status == 5) {
        return "green";
      }
    },
    getStatusTextColor(status) {
      /*1 => Pending, 2 => Sent, 3 => View, 4 => Filled*/
      if (status == 2) {
        return "white";
      } else if (status == 3) {
        return "white";
      } else if (status == 4) {
        return "white";
      } else if (status == 5) {
        return "white";
      }
    },
    getStatusHistoryColor(status) {
      /*1 => Pending, 2 => Sent, 3 => View, 4 => Filled*/
      if (status == "sent") {
        return "cyan";
      } else if (status == "viewed") {
        return "light-blue accent-3";
      } else if (status == "filled") {
        return "orange";
      } else if (status == "submit") {
        return "green";
      }
    },
    getStatusHistoryTextColor(status) {
      /*1 => Pending, 2 => Sent, 3 => View, 4 => Filled*/
      if (status == "sent") {
        return "white";
      } else if (status == "viewed") {
        return "white";
      } else if (status == "filled") {
        return "white";
      } else if (status == "submit") {
        return "white";
      }
    },
    getDoc() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `docs/${_this.docId}/detail` })
        .then(({ data }) => {
          _this.detail = data;
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Doc", route: "doc" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
          const row = _this.lodash.head(data.doc_recipients);
          if (_this.lodash.isEmpty(row) == false) {
            setTimeout(function () {
              _this.showActivity(row);
            }, 200);
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
  },
  mounted() {
    this.docId = this.lodash.toSafeInteger(this.$route.params.id);
    if (!this.docId) {
      this.goBack();
    } else {
      this.getDoc();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Doc", route: "doc" },
      { title: "Detail" },
    ]);
  },
  components: {
    DetailTemplate,
  },
};
</script>
